import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";
import NavDropdown from "react-bootstrap/NavDropdown";
import { Link } from "react-router-dom";

function Navigationbar() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const bg = {
    backgroundColor: "#001D56",
    boxShadow: "rgba(0, 0, 0, 0.4) 0px 8px 24px",
  };

  return (
    <>
      <Navbar style={bg} fixed="top" expand="md">
        <Container fluid>
          <Navbar.Brand as={Link} to="/" className="text-white">
            MoviezLand
          </Navbar.Brand>
          <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-md`} onClick={handleShow} />
          <Navbar.Offcanvas
            show={show}
            onHide={handleClose}
            id={`offcanvasNavbar-expand-md`}
            aria-labelledby={`offcanvasNavbarLabel-expand-md`}
            placement="end"
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title
                id={`offcanvasNavbarLabel-expand-md`}
                className="text-white"
              >
                MoviezLand
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav className="flex-grow-1 pe-3 text-white">
                <NavDropdown
                  className="text-white"
                  title="Filme"
                  id="basic-nav-dropdown"
                >
                  <NavDropdown.Item as={Link} to="/beliebte-filme" onClick={handleClose}>
                    Beliebt
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/laeuft-gerade" onClick={handleClose}>
                    Läuft gerade
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/erscheint-bald" onClick={handleClose}>
                    Bevorstehend
                  </NavDropdown.Item>
                  <NavDropdown.Item as={Link} to="/bestbewertete-filme" onClick={handleClose}>
                    Bestbewertet
                  </NavDropdown.Item>
                </NavDropdown>
                <a href="https://q-tech.dev" target="_blank" rel="noreferrer" className="nav-link text-white" onClick={handleClose}>
                  Q-Tech.dev
                </a>
              </Nav>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
    </>
  );
}

export default Navigationbar;
