import React from "react";

const NotFound = () => {
  return (
    <div
      className="text-white d-flex align-items-center justify-content-center fs-1 fw-bolder"
      style={{ height: "100vh" }}
    >
      <p>404 NOT FOUND</p>
    </div>
  );
};

export default NotFound;
